import 'unfonts.css';
import 'virtual:svg-icons-register';
import '@/css/main.pcss';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes';

import Cookies from 'js-cookie';
import Swiper from 'swiper';

window.Swiper = Swiper;

let xhrPool = [];
const abortAll = () => {
    xhrPool.forEach(function (xhr) {
        xhr.abort();
    });
    xhrPool = [];
};

const abortAjax = (xhr) => {
    if (xhr) {
        let index = xhrPool.indexOf(xhr);
        if (index > -1) {
            xhr.abort();
            xhrPool.splice(index, 1);
        }
    }
}

const _ajax = (url, type = null, callback) => {
    let request;

    if (window.XMLHttpRequest) {
        request = new XMLHttpRequest();
    } else if (window.ActiveXObject) {
        request = new ActiveXObject("Microsoft.XMLHTTP");
    }

    xhrPool.push(request);
    try {
        request.open(type || 'GET', url, true); // 3rd param is whether "async"
    } catch (err) {
        alert("couldnt complete request. Is JS enabled for that domain?\\n\\n" + err.message);
        return false;
    }
    request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    request.send();

    request.onload = () => {
        if (request.status >= 200 && request.status < 400) {
            callback(request);
        } else {
            console.error('request error', request);
            abortAjax(request);
        }
    };

    request.onerror = (err) => {
        console.error('error', err);
        abortAjax(request);
    };
};

const parseHTML = (str = '') => {
    let tmp = document.implementation.createHTMLDocument('tmp');
    tmp.body.innerHTML = str;
    return tmp.body.children;
};

const _si = (selector, context = null, returnNull = false) => {
    let d = context || document;
    let tmp = d.querySelector(selector);
    return tmp ? tmp : returnNull ? null : _c('div');
};
const _s = (selector, context = null) => {
    let d = context || document;
    return Array.apply(null, d.querySelectorAll(selector));
};

const _c = (elm) => {
    return document.createElement(elm);
};

const _ael = (elm, ev, callback) => {
    if (typeof elm === 'string') {
        elm = _si(elm);
    }
    elm.addEventListener(ev, callback);
};

const initSite = () => {
    let html = _si('html'),
        body = document.body,
        canvas, ctx,
        arbeidContainer,
        currentArbeid,
        scrollTop = window.pageYOffset,
        oldScrollTop = 0,
        dist = 20,
        cr = 2,
        window_width = window.innerWidth,
        window_height = window.innerHeight,
        dots = [],
        spikes = [],
        spikeCanvas,
        dotCanvas,
        blobs = [],
        blobTop = window_height,
        blobBottom = 0,
        radius = 100,
        mouse = {x: -500, y: -500},
        r2 = radius * radius,
        centerOffset = dist / 2,
        i, j,
        liste = false,
        currentState = {
            url: location.href.indexOf('#') === -1 ? location.href : location.href.substr(0, location.href.indexOf('#')),
            type: '',
            dir: '',
            level: location.href.substr(0, location.href.length - 1).split('/').length - 2,
            id: 0
        },
        overlay,
        isTransitioning = false,
        swipers = [],
        historyStack = [],
        menuToggle = _si('.menu-toggle'),
        touch = 'ontouchstart' in document,
        quickreads = [],
        resizeTO;

    if (touch) {
        html.classList.add('touch');
    } else {
        html.classList.add('no-touch');
    }

    const easeInOutScroll = (currentTime, start, change, duration) => {
        currentTime /= duration / 2;
        if (currentTime < 1) {
            return change / 2 * currentTime * currentTime + start;
        }
        currentTime -= 1;
        return -change / 2 * (currentTime * (currentTime - 2) - 1) + start;
    }

    const scrollTo = (to, duration) => {
        let start = window.pageYOffset,
            change = to - start,
            increment = 20;

        if (duration === 0) {
            window.scrollTo(0, to);
            return;
        }

        let animateScroll = (elapsedTime) => {
            elapsedTime += increment;
            let position = easeInOutScroll(elapsedTime, start, change, duration);
            window.scrollTo(0, position);
            if (elapsedTime < duration) {
                setTimeout(function () {
                    animateScroll(elapsedTime);
                }, increment);
            }
        };

        animateScroll(0);
    }

    const easeInOut = (t) => {
        return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    }

    const range = (x, org, range) => {
        return Math.min(Math.max(x, org - range), org + range);
    }

    const pointInCircle = (x, y, cx, cy): number => {
        return (x - cx) * (x - cx) + (y - cy) * (y - cy);
    }

    const getNewDist = (d) => {
        d = d / 2000;
        return Math.max(d * (d / 2));
    }

    //Dot class
    const Dot = function (x, y) {
        this.x = x;
        this.y = y;
        this.original = {
            x: x,
            y: y
        };
        this.target = {
            x: x,
            y: y,
            dur: Math.random() * 5000 + 1000,
            start: performance.now(),
            time: 0
        }

        this.draw = function () {
            // let oldTime = this.target.time;
            this.target.time = (performance.now() - this.target.start) / this.target.dur;
            let newX = this.x + ((this.target.x - this.x) * easeInOut(this.target.time));
            let newY = this.y + ((this.target.y - this.y) * easeInOut(this.target.time));

            ctx.beginPath();
            let dist = pointInCircle(mouse.x, mouse.y, newX, newY);
            let tmpX = newX,
                tmpY = newY;
            if (dist <= r2) {
                dist = getNewDist(dist);
                let offX = (newX - mouse.x) / dist;
                let offY = (newY - mouse.y) / dist;
                tmpX += offX;
                tmpY += offY;
            }
            ctx.drawImage(dotCanvas, tmpX, tmpY);

            if (this.target.time >= 0.9) {
                this.x = newX;
                this.y = newY;
                let diffX = mouse.x - this.original.x;
                let diffY = mouse.y - this.original.y;
                let r = Math.sqrt(diffX * diffX + diffY * diffY);
                if (r > centerOffset) {
                    diffX /= r;
                    diffY /= r;
                    diffX *= centerOffset;
                    diffY *= centerOffset;
                }
                let miniOffset = centerOffset / 2;
                let targetX = (this.original.x + diffX) + (Math.random() * miniOffset - miniOffset / 2);
                let targetY = (this.original.y + diffY) + (Math.random() * miniOffset - miniOffset / 2);
                this.target = {
                    x: targetX,
                    y: targetY,
                    dur: Math.random() * 1000 + 1000,
                    start: performance.now(),
                    time: 0
                };
            }
        }
    };

    //Spike class
    const Spike = function (x, y) {
        this.x = x;
        this.y = y;
        this.original = {
            x: x,
            y: y
        };
        this.target = {
            x: x,
            y: y,
            dur: Math.random() * 5000 + 1000,
            start: performance.now(),
            time: 0
        };
        this.size = 5;

        this.draw = function () {
            let dist = 500000 - pointInCircle(mouse.x, mouse.y, this.x, this.y);
            let angleRadians = Math.atan2(mouse.y - this.y, mouse.x - this.x);
            ctx.beginPath();
            ctx.translate(this.x, this.y);
            ctx.rotate(angleRadians);
            ctx.translate(-this.x, -this.y);
            ctx.globalAlpha = Math.max(dist / 800000, 0.3);
            ctx.drawImage(spikeCanvas, this.x - this.size, this.y - this.size);
            ctx.setTransform(1, 0, 0, 1, 0, 0);
        };
    };

    //Blob class
    const Blob = function (points, gradient) {
        this.points = [];
        this.gradient = gradient;
        points.forEach(function (point) {
            this.points.push({
                x: point.x,
                y: point.y,
                offset: 100,
                newX: 0,
                newY: 0,
                original: {
                    x: point.x,
                    y: point.y
                },
                target: {
                    x: point.x,
                    y: point.y,
                    dur: Math.random(),
                    start: performance.now(),
                    time: 0
                }
            });
        }, this);
        this.points.push(this.points[0]);

        this.draw = function () {
            for (let i = 0; i < this.points.length; i++) {
                let point = this.points[i];
                // let oldTime = point.target.time;
                point.target.time = (performance.now() - point.target.start) / point.target.dur;
                point.newX = point.x + ((point.target.x - point.x) * easeInOut(point.target.time));
                point.newY = point.y + ((point.target.y - point.y) * easeInOut(point.target.time));

                if (point.target.time >= 0.9) {
                    point.x = point.newX;
                    point.y = point.newY;

                    let targetX = point.original.x + (Math.random() * point.offset - point.offset / 2);
                    let targetY = point.original.y + (Math.random() * point.offset - point.offset / 2);
                    point.target = {
                        x: targetX,
                        y: targetY,
                        dur: Math.random() * 3000 + 3000,
                        start: performance.now(),
                        time: 0
                    };
                }

                if (i === this.points.length - 2) {
                    this.points[0] = point;
                } else if (i === 1) {
                    this.points[this.points.length - 1] = point;
                }

                blobBottom = Math.max(blobBottom, this.y);
                blobTop = Math.min(blobTop, this.y);
            }

            ctx.beginPath();
            ctx.moveTo((this.points[0].newX + this.points[1].newX) / 2, (this.points[0].newY + this.points[1].newY) / 2);
            for (j = 1; j < this.points.length - 1; j++) {
                let c = (this.points[j].newX + this.points[j + 1].newX) / 2;
                let d = (this.points[j].newY + this.points[j + 1].newY) / 2;
                ctx.quadraticCurveTo(this.points[j].newX, this.points[j].newY, c, d);
            }

            if (this.gradient) {
                let gradient = ctx.createLinearGradient(0, 0, 0, window_height * 0.9);
                gradient.addColorStop(0, '#32DD8E');
                gradient.addColorStop(1, '#091847');
                ctx.fillStyle = gradient;
            } else {
                ctx.fillStyle = '#000';
            }
            ctx.fill();

            let debug = false;
            if (debug) {
                for (j = 1; j < this.points.length - 1; j++) {
                    let circle = new Path2D();
                    circle.moveTo(125, 35);
                    circle.arc(this.points[j].newX, this.points[j].newY, 5, 0, 2 * Math.PI);
                    ctx.fillStyle = i === 0 ? '#f00' : '#00f';
                    ctx.fill(circle);
                    ctx.fillStyle = '#fff';
                    ctx.fillText(j, this.points[j].newX, this.points[j].newY);
                }
            }
        }
    }

    const createDots = () => {
        if (_s('.dots-wrapper').length === 0) {
            return;
        }
        dots = [];
        let margin = cr * 8;
        let wide = Math.floor((window_width - margin * 2) / dist);
        let high = Math.floor(((window_height * 0.9) - (margin * 2)) / dist);
        let wideDist = window_width / wide;
        let highDist = (window_height * 0.9) / high;
        for (i = 0; i <= wide; i++) {
            for (j = 1; j <= high; j++) {
                let left = (i * wideDist) + margin;
                let top = (j * highDist) + margin + Math.round(window_height * 0.15);
                dots.push(new Dot(left, top));
            }
        }

        if (!dotCanvas) {
            dotCanvas = document.createElement('canvas');
            dotCanvas.width = cr * 2;
            dotCanvas.height = cr * 2;
            let dotCanvasCtx = dotCanvas.getContext('2d');
            dotCanvasCtx.arc(cr, cr, cr, 0, 2 * Math.PI, false);
            dotCanvasCtx.fillStyle = '#3E0D99';
            dotCanvasCtx.fill();
        }

        if (touch) {
            let span = _si('.om-oss-wrapper span').getBoundingClientRect();
            mouse.x = span.left + span.width / 2;
            mouse.y = span.top + scrollTop + span.height / 2;
        }
    }

    const renderDots = () => {
        if (dots.length === 0) {
            return;
        }
        requestAnimationFrame(renderDots);
        if (scrollTop > window_height) {
            return;
        }
        ctx.clearRect(0, 0, window_width, window_height);
        dots.forEach(function (dot, i) {
            dot.draw();
        });
    }

    const createSpikes = () => {
        if (_s('.spikes-wrapper').length === 0) {
            return;
        }
        spikes = [];
        let margin = 8;
        let wide = Math.floor((window_width - margin * 2) / (dist * 1.2));
        let high = Math.floor(((window_height * 0.9) - (margin * 2)) / (dist * 1.2));
        let wideDist = window_width / wide;
        let highDist = (window_height * 0.9) / high;
        for (i = 0; i <= wide; i++) {
            for (j = 1; j <= high; j++) {
                let left = (i * wideDist) + margin;
                let top = (j * highDist) + margin + Math.round(window_height * 0.15);
                spikes.push(new Spike(left, top));
            }
        }

        if (!spikeCanvas) {
            spikeCanvas = document.createElement('canvas');
            let spikeSize = 5;
            spikeCanvas.width = spikeSize * 2;
            spikeCanvas.height = spikeSize * 2;
            let spikeCanvaCtx = spikeCanvas.getContext('2d');

            spikeCanvaCtx.beginPath();
            spikeCanvaCtx.strokeStyle = 'rgb(50, 221, 142)';
            spikeCanvaCtx.moveTo(0, 0);
            spikeCanvaCtx.lineTo(spikeSize * 2, spikeSize);
            spikeCanvaCtx.lineTo(0, spikeSize * 2);
            spikeCanvaCtx.lineTo(spikeSize * 2, spikeSize);
            spikeCanvaCtx.closePath();
            spikeCanvaCtx.stroke();
        }

        if (touch) {
            let btn = _si('.forside-intro .btn').getBoundingClientRect();
            mouse.x = btn.left + btn.width / 2;
            mouse.y = btn.top + scrollTop + btn.height / 2;
        }
    }

    const renderSpikes = () => {
        if (spikes.length === 0) {
            return;
        }
        requestAnimationFrame(renderSpikes);
        if (scrollTop > window_height) {
            return;
        }
        ctx.clearRect(0, 0, window_width, window_height);
        spikes.forEach(function (spike, i) {
            spike.draw();
        });
    }

    const createClouds = () => {
        if (_s('.clouds-wrapper').length === 0) {
            return;
        }
        ctx.strokeStyle = '#fff';
        ctx.lineWidth = '2';
        ctx.lineJoin = 'round';
        blobTop = window_height;
        blobBottom = 0;

        blobs[0] = new Blob([
            {x: canvas.width * 0.6, y: window_height * -0.1},
            {x: canvas.width * 1.2, y: window_height * -0.2},
            {x: canvas.width * 1.1, y: window_height * 0.1},
            {x: canvas.width * 0.9, y: window_height * 0.1},
            {x: canvas.width * 0.95, y: window_height * 0.35},
            {x: canvas.width * 0.62, y: window_height * 0.7},
            {x: canvas.width * 0.9, y: window_height * 0.9},
            {x: canvas.width * 0.5, y: window_height * 0.87},
            {x: canvas.width * 0.2, y: window_height * 1},
            {x: canvas.width * 0.4, y: window_height * 0.8},
            {x: canvas.width * 0.35, y: window_height * 0.3},
            {x: canvas.width * 0.55, y: window_height * 0.2},
            {x: canvas.width * 0.55, y: window_height * -0.1}
        ], true);

        blobs[1] = new Blob([
            {x: canvas.width * -0.1, y: window_height * 0.9},
            {x: canvas.width * -0.1, y: window_height * 0.93},
            {x: canvas.width * 0.1, y: window_height * 0.93},
            {x: canvas.width * 0.3, y: window_height * 0.88},
            {x: canvas.width * 0.45, y: window_height * 0.9},
            {x: canvas.width * 0.5, y: window_height * 0.8},
            {x: canvas.width * 0.7, y: window_height * 0.84},
            {x: canvas.width * 0.8, y: window_height * 0.7},
            {x: canvas.width * 1.1, y: window_height * 0.7},
            {x: canvas.width * 1.1, y: window_height * 1.1},
            {x: canvas.width * -0.1, y: window_height * 1.1},
        ], false);

        blobs[2] = new Blob([
            {x: canvas.width * -0.1, y: window_height * -0.1},
            {x: canvas.width * 0.4, y: window_height * -0.1},
            {x: canvas.width * 0.4, y: window_height * 0.1},
            {x: canvas.width * 0.25, y: window_height * 0.1},
            {x: canvas.width * 0.25, y: window_height * 0.25},
            {x: canvas.width * 0.1, y: window_height * 0.2},
            {x: canvas.width * -0.1, y: window_height * 0.3},
            {x: canvas.width * -0.1, y: window_height * -0.1},
        ], false);
    }

    const renderClouds = () => {
        if (blobs.length === 0) {
            return;
        }

        requestAnimationFrame(renderClouds);
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        blobs.forEach(function (blob) {
            blob.draw();
        });
    }

    const renderArbeid = () => {
        if (!currentArbeid) {
            return;
        }
        requestAnimationFrame(renderArbeid);
        if (scrollTop !== oldScrollTop) {
            oldScrollTop = scrollTop;
            currentArbeid.style.transition = 'none';
            if (scrollTop < window_height && window_width > 567) {
                currentArbeid.style.transform = 'translateY(-' + (scrollTop / 2) + 'px)';
            }
            if (scrollTop + window_height > document.body.scrollHeight - document.querySelector('footer').clientHeight) {
                html.classList.add('at--bottom');
            } else {
                html.classList.remove('at--bottom');
            }
        }
    }

    const removeQuickview = (e) => {
        if (e.target.closest('.show--quickview')) {
            return;
        }
        _si('main').removeEventListener('click', removeQuickview);
        _si('.show--quickview .quickclose').click();
    }

    const initArtikler = () => {
        _s('.artikkel').forEach(function (elm) {
            let link = _si('a', elm);
            let url = link.href.replace('/blogg/', '/blogg/quickread/');
            _ajax(url, null, function (data) {
                let elements = parseHTML(data.responseText);
                let quick;
                if (elm.classList.contains('labs')) {
                    for (let i = 0; i < elements.length; i++) {
                        if (elements[i].tagName.toLowerCase() === 'main') {
                            quick = elements[i].querySelector('section');
                            break;
                        }
                    }
                    if (quick.querySelector('h1')) {
                        quick.removeChild(quick.querySelector('h1'));
                    }
                    let div = document.createElement('div');
                    div.classList.add('text');
                    div.innerHTML = quick.innerHTML;
                    quick = div;
                } else {
                    quick = elements[0];
                }
                quickreads[url] = quick;
                let count = quick.innerText.split(' ').length;
                _si('.readtime span', elm).innerText = Math.round(count / 200);
            });
            let elmHeight = elm.parentNode.getBoundingClientRect().height - 16;
            elm.style.height = elmHeight + 'px';
            elm.style.margin = '0';

            let section = _si('section', elm),
                span = _si('.progress span', elm),
                line = _si('.progress i', elm);
            _ael(section, 'scroll', function () {
                let percent = Math.min(section.scrollTop / (section.scrollHeight - elmHeight), 1);
                percent *= 100;
                span.innerText = Math.round(percent) + '%';
                span.style.left = percent + '%';
                span.style.transform = 'translateX(-' + percent + '%)';
                line.style.width = percent + '%';
            });

            _ael(_si('.quickview', elm), 'click', function (e) {
                e.preventDefault();
                let elm = this.parentNode;
                let sec = _si('section', elm);
                let url = _si('a', elm).href.replace('/blogg/', '/blogg/quickread/');
                let height = elm.getBoundingClientRect().height + 'px'
                sec.style.height = 'calc(' + height + ' - 2rem)';
                sec.appendChild(quickreads[url]);
                elm.classList.add('show--quickview');
                html.classList.add('toggle--quickview');
                _ael('main', 'click', removeQuickview);
                let rect = elm.getBoundingClientRect();
                let top = (rect.top + scrollTop) - ((window_height - rect.height) / 2);
                scrollTo(top, 300);
            });

            _ael(_si('.quickclose', elm), 'click', () => {
                let elm = this.parentNode;
                let sec = _si('section', elm);
                sec.removeAttribute('style');
                elm.classList.remove('show--quickview');
                sec.removeChild(_si('.text', sec));
                html.classList.remove('toggle--quickview');
            });

            _ael(link, 'click', function (e) {
                let article = e.target.closest('article');
                if (article.classList.contains('labs')) {
                    return;
                }
                e.preventDefault();
                isTransitioning = true;
                currentState = {
                    type: 'bloggartikkel',
                    url: link.href,
                    level: 3,
                    dir: 'down'
                };
                html.classList.remove('toggle--quickview');
                navigate(link.href, currentState.dir, true);
            });
        });
    }

    const pushState = () => {
        currentState.id = historyStack.length + 1;
        history.pushState(currentState, null, currentState.url);
        historyStack.push(currentState);
    }

    const nodeScriptReplace = (node) => {
        if (node.tagName && node.tagName.toLowerCase() == 'script') {
            node.parentNode.replaceChild(nodeScriptClone(node), node);
        } else {
            let i = 0;
            let children = node.childNodes;
            while (i < children.length) {
                nodeScriptReplace(children[i++]);
            }
        }

        return node;
    }

    const nodeScriptClone = (node) => {
        let script = document.createElement("script");
        script.className = 'dynamic-scripts';
        script.text = node.innerHTML;
        for (let i = node.attributes.length - 1; i >= 0; i--) {
            script.setAttribute(node.attributes[i].name, node.attributes[i].value);
        }
        return script;
    }

    const insertContent = (data, callback) => {
        _s('.dynamic-scripts').forEach(function (s) {
            s.parentNode.removeChild(s);
        });
        let all = parseHTML(data.responseText);
        Array.apply(null, all).forEach(function (elm) {
            let tag = elm.tagName.toLowerCase();
            if (tag === 'main') {
                document.body.insertBefore(elm, _si('.main-footer'));
                setTimeout(function () {
                    _si('main:nth-of-type(2)').classList.add('old-main');
                    document.body.removeChild(_si('.old-main'));
                    init();
                }, 380);
                nodeScriptReplace(elm);
                if (typeof callback === 'function') {
                    callback(elm);
                }
            } else if (tag === 'script') {
                setTimeout(function () {
                    body.className = elm.innerHTML;
                }, 500);
            } else if (tag === 'title') {
                document.title = elm.innerHTML || document.title;
            }
        });
    }

    const addOverlay = (dir) => {
        overlay = document.createElement('div');
        html.classList.add('dir-' + dir);
        overlay.className = 'overlay';
        overlay.innerHTML = '<div></div><div></div><div></div>';
        document.body.appendChild(overlay);
    }

    const gridToArticle = (that, pushHistory) => {
        if (pushHistory) {
            pushState();
        }
        let rect = that.getBoundingClientRect();
        that.classList.add('start');
        html.classList.add('subpage');
        _si('.type-liste').classList.remove('type-liste');
        let startAnimate = performance.now();
        scrollTo(rect.top + scrollTop, 150);
        setTimeout(function () {
            that.parentNode.insertBefore(that.cloneNode(), that);
            that.style.left = rect.left + 'px';
            that.style.right = rect.right + 'px';
            that.classList.add('fixed');
        }, 150);
        setTimeout(function () {
            that.classList.add('full');
        }, 200);
        let bg = that.style.backgroundImage;
        abortAll();
        _ajax(that.href, null, function (data) {

            html.classList.remove('lastet');
            let doneLoading = performance.now();
            window.scrollTo(0, 0);
            setTimeout(function () {
                window.scrollTo(0, 0);
                insertContent(data, function (el) {
                    let temp = _si('.temp-bilde', el);
                    if (temp && bg) {
                        temp.style.backgroundImage = bg;
                    }
                    window.scrollTo(0, 0);
                });
            }, 600 - (doneLoading - startAnimate));
        });
    }

    const navigate = (url, dir, pushHistory) => {
        let startAnimate = performance.now();
        if (pushHistory) {
            pushState();
        }
        addOverlay(dir);
        swipers.forEach(function (swiper) {
            swiper.destroy();
        });
        swipers = [];

        setTimeout(function () {
            overlay.classList.add('in');
            _si('main').classList.add('out');
        }, 40);
        html.classList.remove('toggle--quickview');

        abortAll();
        _ajax(url, null, function (data) {
            html.classList.remove('lastet');
            insertContent(data, function (elm) {
                elm.classList.add('out', 'new');
            });
            let doneLoading = performance.now();
            setTimeout(function () {
                let box = _si('.new');
                let activeUrl = url.split('/')[3];
                _si('.main-header .active').classList.remove('active');
                if (activeUrl !== '') {
                    if (activeUrl.indexOf('#') > -1) {
                        activeUrl = activeUrl.substr(0, activeUrl.indexOf('#'));
                    }
                    _si('.main-header a[href*="' + activeUrl + '"]').parentNode.classList.add('active');
                }
                window.scrollTo(0, 0);
                box.classList.remove('out');
                overlay.classList.add('out');
                setTimeout(function () {
                    document.body.removeChild(overlay);
                    box.classList.remove('new');
                    html.classList.remove('show--menu', 'no-scroll');

                    setTimeout(function () {
                        html.classList.remove('dir-' + dir);
                    }, 300);
                }, 600);
            }, 600 - (doneLoading - startAnimate));
        });
    }

    const getOppositeDirection = () => {
        switch (currentState.dir) {
            case 'left':
                return 'right';
            case 'right':
                return 'left';
            case 'up':
                return 'down';
            case 'down':
                return 'up';
        }
    }

    const closeCategoryMenu = () => {
        _si('.list-header ul').classList.remove('open');
        document.removeEventListener('click', closeCategoryMenu);
    }

    const init = () => {
        console.log('init')
        arbeidContainer = _si('.arbeid-container');
        currentArbeid = _si('.single .arbeid', null, true);
        dots = [];
        spikes = [];
        blobs = [];

        if (_s('.dots-wrapper').length || _s('.spikes-wrapper').length || _s('.clouds-wrapper').length) {
            canvas = _si('canvas');
            ctx = canvas.getContext('2d');
            canvas.width = window_width;
            canvas.height = window_height;
            _si('.clouds-wrapper').style.height = window_height + 'px';
            if (_s('.clouds-wrapper').length && window_width <= 567) {
                canvas.width = window_width * 2;
            }
            createDots();
            createSpikes();
            createClouds();

            _si('canvas').parentNode.addEventListener('mousemove', function (e) {
                mouse.x = e.pageX;
                mouse.y = e.pageY;
            });
        }

        _s('.module--slideshow').forEach(function (elm, i) {
            let className = '.swiper-' + i;
            elm.classList.add(className.replace('.', ''));
            let tmp = new Swiper(className + ' .swiper-container', {
                navigation: {
                    nextEl: className + ' .swiper-button-next',
                    prevEl: className + ' .swiper-button-prev',
                }
            });
            swipers.push(tmp);
        });

        isTransitioning = false;
        if (Cookies.get('stop-being-annoying')) {
            _si('.annoying').remove();
        } else {
            _ael('.annoying button', 'click', function () {
                Cookies.set('stop-being-annoying', 1, {expires: 30});
                _si('.annoying').classList.add('skjul');
            });
        }
        html.classList.add('lastet');

        initArtikler();
        renderDots();
        renderSpikes();
        renderArbeid();
        renderClouds();
        if (typeof initBlogg === 'function') {
            initBlogg();
        }
        setTimeout(function () {
            html.classList.remove('firstLoad');
        }, 400);

        if (location.hash.length > 2) {
            let person = _si('[data-url="' + location.hash.replace('#', '') + '"]', document, true);
            if (person) {
                setTimeout(function () {
                    scrollTo(person.getBoundingClientRect().top + scrollTop, 200);
                }, 500);
            }
        }


        _s('.js--kontaktbilde').forEach(function (elm) {
            _ael(elm, 'mouseenter', function (e) {
                const pictures = _s('picture:nth-child(n+2)', elm);
                const rand = pictures[Math.floor(Math.random() * pictures.length)];
                rand?.classList.add('block');
            });
            _ael(elm, 'mouseleave', function (e) {
                const picture = _si('picture.block', elm);
                picture?.classList.remove('block');
            });
        });


        if (typeof ga === 'function') {
            ga('send', 'pageview');
        }
    }

    init();

    let delays = '';
    for (i = 1; i < 5; i++) {
        for (j = 1; j < 31; j++) {
            let del = (j * 0.015) + ((i - 1) * 0.2) + 0.3;
            delays += '.arbeid h1 div:nth-child(' + i + ') span:nth-child(' + j + ') { transition-delay: ' + del + 's; }';
            if (i === 1) {
                delays += '.main-header nav span:nth-child(' + j + ') { transition-delay: ' + del + 's; }';
            }
        }
    }
    let s = document.createElement('style');
    s.appendChild(document.createTextNode(delays));
    document.head.appendChild(s);

    document.addEventListener('click', function (e) {
        let that = (e.target as HTMLElement).closest('.list-style svg, .list-style i');
        if (!that || that.classList.contains('active')) {
            return;
        }
        liste = that.classList.contains('liste');
        _s('.arbeid').forEach(function (box) {
            if (liste) {
                let span = box.querySelector('.content span');
                if (span) {
                    let rect = span.getBoundingClientRect();
                    let bRect = box.querySelector('.content').getBoundingClientRect();
                    let newSpan: HTMLSpanElement = document.createElement('span');
                    newSpan.className = 'small-title';
                    newSpan.innerHTML = span.innerHTML;
                    newSpan.style.left = (rect.left - bRect.left) + 'px';
                    newSpan.style.bottom = (bRect.bottom - rect.bottom - 1) + 'px';
                    box.querySelector('.content').appendChild(newSpan);
                    setTimeout(function () {
                        newSpan.style.left = '0';
                        newSpan.style.bottom = '0';
                    }, 10);
                }
            } else {
                box.querySelector('.content').removeChild(box.querySelector('.small-title'));
            }
        });
        let text = _si('.list-header i:not(.active)'),
            icon = _si('.list-header svg:not(.active)');
        _si('.list-style i.active').classList.remove('active');
        _si('.list-style svg.active').classList.remove('active');
        text.classList.add('active');
        icon.classList.add('active');
        arbeidContainer.classList.remove('type-bilde', 'type-liste');
        arbeidContainer.classList.add('type-' + (liste ? 'liste' : 'bilde'));
    });

    document.addEventListener('click', function (e) {
        let list = (e.target as HTMLElement).closest('.list-header ul');
        if (!list) {
            return;
        }
        if (window_width < 768 && !list.classList.contains('open')) {
            list.classList.add('open');
            document.addEventListener('click', closeCategoryMenu);
            return;
        }
        let that = (e.target as HTMLElement).closest('a');
        if (!that) {
            return;
        }
        if ((that.parentNode as HTMLElement).classList.contains('active')) {
            return;
        }
        list.classList.remove('open');
        _si('.active', list).classList.remove('active');
        (that.parentNode as HTMLElement).classList.add('active');
        let cat = that.href;
        cat = cat.substr(cat.indexOf('#') + 1);

        _s('[data-cat]').forEach(function (arbeid) {
            if (cat === 'alt' || arbeid.getAttribute('data-cat').split(',').indexOf(cat) !== -1) {
                arbeid.classList.remove('hidden');
            } else {
                arbeid.classList.add('hidden');
            }
        });
    });

    if (location.hash.length > 2) {
        _si('.list-header a[href="' + location.hash + '"]').click();
    }

    window.addEventListener('resize', function () {
        clearTimeout(resizeTO);
        resizeTO = setTimeout(function () {
            window_width = window.innerWidth;
            window_height = window.innerHeight;
        }, 100);
    });

    _ael('nav', 'mouseenter', function () {
        html.classList.remove('skjul-meny-el');
    });

    _ael('nav', 'mouseleave', function () {
        if (scrollTop > 30) {
            html.classList.add('skjul-meny-el');
        }
    });

    document.addEventListener('scroll', function () {
        scrollTop = window.pageYOffset;

        if (scrollTop > 30) {
            html.classList.add('skjul-meny-el');
        } else {
            html.classList.remove('skjul-meny-el');
        }
    });

    document.addEventListener('click', function (e) {
        if (isTransitioning) {
            return false;
        }
        let that = (e.target as HTMLElement).closest('.single nav a');
        if (!that) {
            return;
        }
        e.preventDefault();
        isTransitioning = true;
        if (that.classList.contains('to-grid')) {
            currentState = {
                type: 'list',
                url: that.href,
                level: 2,
                dir: 'up'
            };
            navigate(that.href, 'up', true);
        } else {
            currentState = {
                type: 'article',
                url: that.href,
                level: 3,
                dir: that.getAttribute('data-direction')
            };
            let dir = that.getAttribute('data-direction');
            navigate(that.href, dir, true);
        }
    });

    _s('.logo').forEach(function (logo) {
        _ael(logo, 'click', function (e) {
            if (isTransitioning) {
                return false;
            }
            e.preventDefault();
            isTransitioning = true;
            let url = this.href;
            currentState = {
                type: 'list',
                url: url,
                level: 1,
                dir: 'up'
            };
            navigate(url, 'up', true);
        });
    });

    _ael('.main-header nav', 'click', function (e) {
        if (isTransitioning) {
            return false;
        }
        e.preventDefault();
        let that = e.target.closest('.main-header nav a');
        if (that) {
            isTransitioning = true;
            let prevLevel = currentState.level;
            let dir = prevLevel === 1 ? 'down' : 'up';
            if (prevLevel === 2) {
                let allnodes = _s('.main-header li');
                let prevIndex = allnodes.indexOf(_si('.main-header .active'));
                let thisIndex = allnodes.indexOf(that.parentNode);
                dir = prevIndex > thisIndex ? 'left' : 'right';
            }
            currentState = {
                type: 'list',
                url: that.href,
                level: 2,
                dir: dir
            };
            navigate(that.href, currentState.dir, true);
        }
    });

    _ael(document, 'click', function (e) {
        if (isTransitioning) {
            return false;
        }
        let that = e.target.closest('.arbeid-container .arbeid');
        if (!that) {
            return;
        }
        e.preventDefault();
        isTransitioning = true;
        currentState = {
            type: 'article',
            url: that.href,
            level: 3,
            dir: 'down'
        };
        gridToArticle(that, true);
    });

    document.addEventListener('click', function (e) {
        let that = e.target.closest('.ansatte-liste .more');
        if (that) {
            let art = that.closest('article');
            scrollTo(art.getBoundingClientRect().top + scrollTop - (window_height / 20), 300);
            art.classList.toggle('show--extra');
        }
    });

    document.addEventListener('click', function (e) {
        let that = e.target.closest('.ansatte-liste .close');
        if (that) {
            that.closest('article').classList.remove('show--extra');
        }
    });

    document.addEventListener('click', function (e) {
        let that = e.target.closest('.kontaktskjema .done');
        if (that) {
            e.preventDefault();
            let form = that.closest('form'),
                active = _si('.active', form);

            active.classList.add('out-big');
            form.classList.add('form--sent');
        }
    });

    document.addEventListener('click', function (e) {
        let that = e.target.closest('.kontaktskjema .next');
        if (that) {
            let form = that.closest('form'),
                active = _si('.active', form),
                next = active.nextElementSibling,
                num = next.getAttribute('data-num');

            active.classList.add('out');
            next.classList.add('out');
            _si('.page-count span', form).innerHTML = num;
            setTimeout(function () {
                active.classList.remove('active', 'out');
                next.classList.add('active');
                if (next.classList.contains('last')) {
                    that.classList.add('hidden');
                    _si('.done', form).classList.remove('hidden');
                }
                if (active.classList.contains('first')) {
                    _si('.prev', form).removeAttribute('disabled');
                }
                setTimeout(function () {
                    next.classList.remove('out');
                }, 10);
            }, 200);
        }
    });

    document.addEventListener('click', function (e) {
        let that = e.target.closest('.kontaktskjema .prev');
        if (that && !that.getAttribute('disabled')) {
            let form = that.closest('form');
            let active = _si('.active', form);
            active.classList.remove('active');
            let prev = active.previousElementSibling;
            let num = prev.getAttribute('data-num');
            _si('.page-count span', form).innerHTML = num;
            prev.classList.add('active');
            if (active.classList.contains('last')) {
                _si('.next', form).classList.remove('hidden');
                _si('.done', form).classList.add('hidden');
            }
            if (prev.classList.contains('first')) {
                _si('.prev', form).setAttribute('disabled', true);
            }
        }
    });

    let startPos = 0,
        touchMoved = 0;

    menuToggle.addEventListener('touchstart', function (e) {
        startPos = e.touches[0].clientY;
        touchMoved = 0;
        menuToggle.classList.remove('anim');
    });

    menuToggle.addEventListener('touchmove', function (e) {
        e.preventDefault();
        if (html.classList.contains('show--menu')) {
            return false;
        }
        //console.log('touchmove');
        touchMoved = (startPos - e.touches[0].clientY);
        menuToggle.style.height = Math.max(45, 45 + (touchMoved / 3)) + 'px';
        if (Math.abs(touchMoved) > 1) {
            html.classList.add('no-scroll');
        }
        return false;
    });

    menuToggle.addEventListener('touchend', function (e) {
        if (Math.abs(touchMoved) > 1) {
            //console.log('touchend');
            menuToggle.classList.add('anim');
            menuToggle.style.height = '45px';
            // html.classList.remove('no-scroll');
            html.classList.add('show--menu');
            return false;
        }
    });

    menuToggle.addEventListener('click', function () {
        if (html.classList.contains('show--menu')) {
            html.classList.remove('show--menu');
            html.classList.remove('no-scroll');
        } else {
            html.classList.add('show--menu');
            html.classList.add('no-scroll');
        }
        //console.log('click');

    });

    _ael(document, 'click', (e) => {
        let that = e.target.closest('a');
        if (that) {
            let localHash = that.href.indexOf('#') && that.href.indexOf(location.pathname) > -1;
            if (that.href.indexOf(siteUrl) === -1 || localHash) {
                return;
            }
            if (isTransitioning) {
                return false;
            }
            e.preventDefault();
            isTransitioning = true;
            let dir = that.href.indexOf('/arbeid/') === -1 ? 'right' : 'left';
            dir = currentState.level === 1 ? 'down' : dir;
            currentState = {
                type: 'article',
                url: that.href,
                level: 3,
                dir: dir
            };
            navigate(that.href, currentState.dir, true);
        }
    });

    window.addEventListener('popstate', function (e) {
        let url = '';
        if (location.href.substr(0, location.href.indexOf('#')) === currentState.url) {
            return;
        }
        let targetState = e.state || historyStack[0];
        let transition = currentState.type + '-to-' + targetState.type;
        let type = '';
        if (transition === 'list-to-article') {
            if (_s('.arbeid-container').length > 0) {
                type = 'open-article';
            } else {
                type = 'normal';
            }
        } else {
            type = 'normal';
        }
        let isBack = currentState.id > targetState.id
        let transitionDir = isBack ? getOppositeDirection(e) : targetState.dir;
        currentState = {
            url: targetState.url,
            type: targetState.type,
            dir: targetState.dir,
            level: targetState.level,
            id: targetState.id
        };
        if (isBack) {
            historyStack = historyStack.slice(0, historyStack.length - 1);
        } else {
            historyStack.push(currentState);
        }

        if (type === 'open-article') {
            url = currentState.url.split('/');
            gridToArticle(_si('a[href*="' + url[url.length - 1] + '"]'), false);
        } else if (type === 'normal') {
            navigate(location.href, transitionDir, false);
        }
    });
}

// initSite();
window.addEventListener('load', initSite);
